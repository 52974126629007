import axios from "axios";

class AdminService {

  protected instance: any;
  private baseUrl = '';

  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH || '';
    this.instance = axios.create({
      baseURL: this.baseUrl,
    });
  }

  public getUsers() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allu.php")
      .then(response => {
        return response.data;
      });
  }

  public getTemplates(id: String) : Promise<any> {
    return axios
      .get(this.baseUrl + "/alltemplates.php?id="+id)
      .then(response => {
        return response.data;
      });
  }

  public getBrokers() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allb.php")
      .then(response => {
        return response.data;
      });
  }

  public getProperties() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allproperties.php")
      .then(response => {
        return response.data;
      });
  }

  public getUser(data : any) : Promise<any> {
    return axios
    .post(this.baseUrl + "/getUser.php",data)
    .then(response => {
      return response.data;
    });
  }

  public getCode(data : string) : Promise<any> {
    return axios
    .get(this.baseUrl + "/getcode.php?id="+data)
    .then(response => {
      return response.data;
    });
  }

  public getAnnounce(): Promise<any>{
    return axios
    .get(this.baseUrl + "/getAnnounce.php")
    .then(response => {
      return response.data;
    });
  }

  
  public getAnnounces(): Promise<any>{
    return axios
    .get(this.baseUrl + "/getAnnounces.php")
    .then(response => {
      return response.data;
    });
  }

  public createUser(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/createUser.php",data)
      .then(response => {
        return response.data;
      });
  }

  public modifyUser(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/modifyUser.php",data)
      .then(response => {
        return response.data;
      });
  }

  public registerTemplate(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/registertemplate.php",data)
      .then(response => {
        return response.data;
      });
  }

  public changepwd(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/changePwd.php",data)
      .then(response => {
        return response.data;
      });
  }

  public changeAnnounce(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/changeAnnounce.php",data)
      .then(response => {
        return response.data;
      });
  }
  

}

export default AdminService;