import * as React from 'react';
import './adminpanel.css';
import darkLogo from './../../assets/hubLogo.png'
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';
import fontBack from './../../assets/totalEmail.png'

interface IStateAdminPanelForm {
    id: string;
    title: string;
    name: string;
    city: string;
    desc1: string;
    desc2: string;
    desc3: string;
    desc4: string;
    price: string;
    text: string;
    photo1: any;
    photo2: any;
    photo3: any;
    uri: string;
    photoperfil: any;
    fullname: string;
    mail: string;
    phone: string;
    facebook: string;
    youtube: string;
    instagram: string;
}

class NewTemplate extends React.Component<{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    constructor(props: any) {
        super(props);

        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleDesc1Change = this.handleDesc1Change.bind(this);
        this.handleDesc2Change = this.handleDesc2Change.bind(this);
        this.handleDesc3Change = this.handleDesc3Change.bind(this);
        this.handleDesc4Change = this.handleDesc4Change.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handlePhoto1Change = this.handlePhoto1Change.bind(this);
        this.handlePhoto2Change = this.handlePhoto2Change.bind(this);
        this.handlePhoto3Change = this.handlePhoto3Change.bind(this);
        this.handleUriChange = this.handleUriChange.bind(this);
        this.handlePhotoperfilChange = this.handlePhotoperfilChange.bind(this);
        this.handleFullnameChange = this.handleFullnameChange.bind(this);
        this.handleMailChange = this.handleMailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleFacebookChange = this.handleFacebookChange.bind(this);
        this.handleYoutubeChange = this.handleYoutubeChange.bind(this);
        this.handleInstagramChange = this.handleInstagramChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancelReg = this.cancelReg.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);

        this.state = {
            id: localStorage.getItem("id") || "",
            title: '',
            name: '',
            city: '',
            desc1: '',
            desc2: '',
            desc3: '',
            desc4: '',
            price: '',
            text: '',
            photo1: null,
            photo2: null,
            photo3: null,
            uri: '',
            photoperfil: null,
            fullname: '',
            mail: '',
            phone: '',
            facebook: '',
            youtube: '',
            instagram: '',
        };
    }


    public handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ title: event.target.value });
    public handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ name: event.target.value });
    public handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ city: event.target.value });
    public handleDesc1Change = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ desc1: event.target.value });
    public handleDesc2Change = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ desc2: event.target.value });
    public handleDesc3Change = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ desc3: event.target.value });
    public handleDesc4Change = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ desc4: event.target.value });
    public handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ price: event.target.value });
    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ text: event.target.value });
    public handlePhoto1Change = (event: any) => {
            const file = event.target.files[0];
            console.log(file);
            this.setState({ photo1: file });
    }
    public handlePhoto2Change = (event: any) => {
        const file = event.target.files[0];
        this.setState({ photo2: file });
    }
    public handlePhoto3Change = (event: any) => {
        const file = event.target.files[0];
        this.setState({ photo3: file });
    }
    public handleUriChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ uri: event.target.value });
    public handlePhotoperfilChange = (event: any) => {
        const file = event.target.files[0];
        this.setState({ photoperfil: file });
    }
    public handleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ fullname: event.target.value });
    public handleMailChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ mail: event.target.value });
    public handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ phone: event.target.value });
    public handleFacebookChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ facebook: event.target.value });
    public handleYoutubeChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ youtube: event.target.value });
    public handleInstagramChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ instagram: event.target.value });

    public handleSubmit = (event: any) => {
        event.preventDefault();

        const formData = new FormData();

        formData.append('id', this.state.id);
        formData.append('title', this.state.title);
        formData.append('name', this.state.name);
        formData.append('city', this.state.city);
        formData.append('desc1', this.state.desc1);
        formData.append('desc2', this.state.desc2);
        formData.append('desc3', this.state.desc3);
        formData.append('desc4', this.state.desc4);
        formData.append('price', this.state.price);
        formData.append('text', this.state.text);
        formData.append('photo1', this.state.photo1);
        formData.append('photo2', this.state.photo2);
        formData.append('photo3', this.state.photo3);
        formData.append('uri', this.state.uri);
        formData.append('photoperfil', this.state.photoperfil);
        formData.append('fullname', this.state.fullname);
        formData.append('mail', this.state.mail);
        formData.append('phone', this.state.phone);
        formData.append('facebook', this.state.facebook);
        formData.append('youtube', this.state.youtube);
        formData.append('instagram', this.state.instagram);

        this.adminService.registerTemplate(formData).then(
            (response: any) => {
                window.location.assign(process.env.PUBLIC_URL + "/templates");
            });

    }

    public cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL + "/templates");
    };

    componentDidMount() {

    }

    public render() {

        const labelStyle = {
            display: 'block',
            backgroundColor: '#007bff',
            color: 'white',
            padding: '10px',
            cursor: 'pointer',
            textAlign: 'center',
        };

        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                    <MenuPrincipal />
                    <div style={{
                        height: 500, width: '100%', paddingLeft: '50px',
                        paddingRight: '50px', paddingBottom: '50px', paddingTop: '20px'
                    }}>
                        <h4>Make my Email Template</h4>
                        <br />
                        <br />
                        <form onSubmit={this.handleSubmit}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1, paddingRight: '20px' }}>
                                    <img
                                        src={fontBack}
                                        alt="Imagen grande"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </div>

                                <div style={{ flex: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="title"
                                                label="1- Title"
                                                variant="outlined"
                                                value={this.state.title}
                                                onChange={this.handleTitleChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="name"
                                                label="2- Property"
                                                variant="outlined"
                                                value={this.state.name}
                                                onChange={this.handleNameChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="city"
                                                label="3- City"
                                                variant="outlined"
                                                value={this.state.city}
                                                onChange={this.handleCityChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="desc1"
                                                label="4- Description 1"
                                                variant="outlined"
                                                value={this.state.desc1}
                                                onChange={this.handleDesc1Change}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="desc2"
                                                label="5- Description 2"
                                                variant="outlined"
                                                value={this.state.desc2}
                                                onChange={this.handleDesc2Change}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="desc3"
                                                label="6- Description 3"
                                                variant="outlined"
                                                value={this.state.desc3}
                                                onChange={this.handleDesc3Change}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="desc4"
                                                label="7- Description 4"
                                                variant="outlined"
                                                value={this.state.desc4}
                                                onChange={this.handleDesc4Change}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="price"
                                                label="8- Price"
                                                variant="outlined"
                                                value={this.state.price}
                                                onChange={this.handlePriceChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                        
                                        <label htmlFor="fileInput" style={{display: 'block',
                                        backgroundColor: '#dfbf6d',
                                        color: 'white',
                                        padding: '10px',
                                        cursor: 'pointer',
                                        textAlign: 'left',}}>
                                            9- Photo 1 Principal
                                        </label>
                                        <input
                                            id="fileInput"
                                            type="file"
                                            name="photo1"
                                            accept="image/*"
                                            onChange={this.handlePhoto1Change}
                                        />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <label htmlFor="fileInput" style={{display: 'block',
                                            backgroundColor: '#dfbf6d',
                                            color: 'white',
                                            padding: '10px',
                                            cursor: 'pointer',
                                            textAlign: 'left',}}>
                                                10- Photo 2
                                            </label>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                name="photo2"
                                                accept="image/*"
                                                onChange={this.handlePhoto2Change}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                        <label htmlFor="fileInput" style={{display: 'block',
                                            backgroundColor: '#dfbf6d',
                                            color: 'white',
                                            padding: '10px',
                                            cursor: 'pointer',
                                            textAlign: 'left',}}>
                                                11- Photo 3
                                            </label>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                name="photo3"
                                                accept="image/*"
                                                onChange={this.handlePhoto3Change}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="text"
                                                label="12- Text"
                                                variant="outlined"
                                                value={this.state.text}
                                                onChange={this.handleTextChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="uri"
                                                label="Url for Photos"
                                                variant="outlined"
                                                value={this.state.uri}
                                                onChange={this.handleUriChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                             <label htmlFor="fileInput" style={{display: 'block',
                                            backgroundColor: '#dfbf6d',
                                            color: 'white',
                                            padding: '10px',
                                            cursor: 'pointer',
                                            textAlign: 'left',}}>
                                                13- Profile Photo
                                            </label>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                name="photoperfil"
                                                accept="image/*"
                                                onChange={this.handlePhotoperfilChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="fullname"
                                                label="14- Full Name"
                                                variant="outlined"
                                                value={this.state.fullname}
                                                onChange={this.handleFullnameChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="mail"
                                                label="15- Email"
                                                variant="outlined"
                                                value={this.state.mail}
                                                onChange={this.handleMailChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="phone"
                                                label="16- Phone"
                                                variant="outlined"
                                                value={this.state.phone}
                                                onChange={this.handlePhoneChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="facebook"
                                                label="17- Facebook"
                                                variant="outlined"
                                                value={this.state.facebook}
                                                onChange={this.handleFacebookChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="youtube"
                                                label="18- YouTube"
                                                variant="outlined"
                                                value={this.state.youtube}
                                                onChange={this.handleYoutubeChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="instagram"
                                                label="19- Instagram"
                                                variant="outlined"
                                                value={this.state.instagram}
                                                onChange={this.handleInstagramChange}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container style={{ justifyContent: 'flex-end' }}>
                                        <Button type="submit" variant="contained" style={{ margin: '8px', backgroundColor: '#dfbf6d' }}>
                                            Modify
                                        </Button>
                                        <Button type="button" onClick={this.cancelReg} variant="contained" style={{ margin: '8px', backgroundColor: '#808080' }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <br></br>
                                    <br></br>
                                </div>
                            </div>
                        </form>
                    </div>
                </section >               
                
            <div className="footer-home">
                Mainstreet Realtors Agent Hub
            </div>
            </div >
        );
    }
}

export default NewTemplate;