import * as React from 'react';
import './maps.css';

import darkLogo from './../../assets/hubLogo.png'

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Avatar, Badge, Button, Card, CardContent, CardHeader, Grid, IconButton,Popover,Slider, Typography } from '@mui/material';
import { GoogleMap, LoadScript, Marker, InfoWindow  } from '@react-google-maps/api';

import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
  rows: GridRowsProp;
  selectionModel: any;
  selectedLocation: any;
  cities: any;
  priceRange: any;
  citiesBruto: any;
  anchorEl: any;
}


class Maps extends React.Component<{}, IStateAdminPanelForm> {
  adminService = new AdminService();

  constructor(props: any) {
    super(props);
    this.filterProducts = this.filterProducts.bind(this);
    this.handleCityClick = this.handleCityClick.bind(this);
    this.state = {
      rows: [],
      selectionModel: [],
      selectedLocation: null,
      cities: [],
      citiesBruto: [],
      priceRange: [0, 1000000],
      anchorEl: null,
    };


  }

  componentDidMount() {
    this.adminService.getProperties().then(
      (response) => {
        this.setState({ cities: response , citiesBruto: response});
      });
  }

  public handlePriceChange = (event: any, newValue:  any) => {
    this.setState({priceRange: newValue });
    this.filterProducts(newValue);
  };

  public filterProducts = (range: any) => {
    const filteredProducts = this.state.citiesBruto.filter((product: any) => {
      return product.lastprice >= range[0] && product.lastprice <= range[1];
    });
    this.setState({ cities: filteredProducts});
  };

  public  handleCityClick = (city:any) => {
    this.setState({ selectedLocation: city });
    const mapSection = document.getElementById('mapSection');
      if (mapSection) {
        mapSection.scrollIntoView({ behavior: 'smooth' });
      }
  };

  public handleClick = (event: any) => {
    this.setState({ anchorEl : event.currentTarget});
  };

  public handleClose = () => {
    this.setState({ anchorEl : null});
  };

  public render() {

    const containerStyle = {
      width: '100%',
      height: '700px'
    };
    const center = {
      lat: 34.0616948,
      lng: -117.5335737
    };

    const imageStyle = {
      width: '200px',
      height: '180px',
    };
    
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'slider-popover' : undefined;

    return (
      <div className="adminpanel">
        <section id="neighborhood_heading" className="cy-custom">
        <MenuPrincipal />  
          <div style={{
            height: 500, width: '100%', paddingBottom: '30px', paddingTop: '20px'
          }}>

            <div className="titleMap">
              <span>INTERACTIVE
                <b>MAP</b>
                <i className="title-line" />
              </span>
            </div>
            <br />
            <div className="rootsmaps">
            <Grid container> 
            <div className='pullRight'>
            <Button onClick={this.handleClick} className='buttonRange'>Range of Prices</Button>
              <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div style={{ padding: 16 }}>
                  
                  <Grid container spacing={2} justifyContent="center" style={{width:'500px', marginLeft: '20px'}}>
                  <Grid item>
                  <Typography variant="body1">$0</Typography>
                  </Grid>
                  <Grid item xs >
                  <Slider
                              value={this.state.priceRange}
                              onChange={this.handlePriceChange}
                              valueLabelDisplay="auto"
                              min={0}
                              max={1000000}
                              aria-labelledby="range-slider"
                            />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">$1M</Typography>
                  </Grid>       
                  </Grid>
                </div>
              </Popover>
              </div>
              
              <div id="mapSection" />
                <LoadScript
                  googleMapsApiKey="AIzaSyD9Ri5i_Ler_a6ZPZ1zfYSUZrbeFO-xNEk"
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    options={{mapTypeId: 'terrain'}}
                  >
                    {this.state.cities.map((city: any, index: any) => (
                      // <Marker key={index} position={city.location} onClick={() => this.setState({selectedLocation:city})} />
                      <Marker
                        position={city.location}
                        onClick={() => {
                          this.setState({ selectedLocation: city });
                        }}
                        icon={{
                          url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(`
                                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <circle cx="15" cy="15" r="15" fill="red" style="fill: radial-gradient(circle, #800000, #FF0000); stroke: #FF8080; stroke-width: 2px; filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));"/>
                                        <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="14" dominant-baseline="central">
                                        `+ city.id + `
                                        </text>
                                      </svg>
                                    `),
                        }}
                        
                      />
                    ))}
                    {this.state.selectedLocation && (
                      <InfoWindow
                        position={this.state.selectedLocation.location}
                        onCloseClick={() => this.setState({ selectedLocation: null })}
                      >
                         <div style={{display: 'flex',flexDirection: 'row'}}>
                          <img  src={`https://maps.googleapis.com/maps/api/streetview?size=200x148&location=${this.state.selectedLocation.location.lat},${this.state.selectedLocation.location.lng}&key=AIzaSyBVdBZs1kfduuQXVAKD2mq_hjUA6ZDhWmU`}
                          alt="Imagen" style={imageStyle} />
                          <div style={{padding: '10px'}}>
                          <p><Badge badgeContent="SOLD" color="error" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}></Badge></p>
                            <h5>{this.state.selectedLocation.name}</h5>
                            <span className='info-price'>{this.state.selectedLocation.description.toLocaleString('es-PE', { style: 'currency', currency: 'USD' })} </span>
                          </div>
                        </div>
                        
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </LoadScript>
                <Grid container spacing={2} style={{margin: '40px', textAlign: 'left'}} >
                {this.state.cities.map((cities: any, index:any) => (
                  <Grid item xs={12} sm={6} md={4} key={cities.id}>
                    <a style={{cursor: "pointer"}} onClick={() => this.handleCityClick(cities)}>
                      {`${index + 1}. ${cities.name} - ${cities.description}`}
                      </a>
                  </Grid>
                ))}
              </Grid>
              </Grid>
            </div>


          </div>
        </section>
        <div className="footer-home">
          Mainstreet Realtors Agent Hub
        </div>
      </div>
    );
  }
}

export default Maps;