import * as React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './templates.css';
import FolderTree  from 'react-folder-tree';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';
import { Badge, Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import TemplateIcon from '@mui/icons-material/Description'; // Reemplaza con el icono correcto
import DocumentsIcon from '@mui/icons-material/Description'; // Reemplaza con el icono correcto
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import AdminService from '../../services/Admin.service';

interface IStateAdminPanelForm {
    rows: GridRowsProp;
    selectionModel:any;
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 50 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'type', headerName: 'Type', width: 200,
        renderCell: (params) => {
            return "Template " + params.row.type;
        }, 
    },
    { field: 'regdate', headerName: 'Register Date', width: 300 },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 250,
        renderCell: (params) => {
          const handleButtonClick = () => {
            localStorage.setItem("template", ""+params.id);
            window.location.assign(process.env.PUBLIC_URL+"/viewcode/"+params.id);
          };
          return <div className="divButton">
                <Button variant="outlined" onClick={handleButtonClick} style={{margin: '8px'}}>View Code</Button>
                </div>;
        },
      },
  ];

class Templates extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    constructor(props: any) {
        super(props);
        this.state = {
            rows: [],
            selectionModel: []
        };
    }

    componentDidMount(){
        const isAuthenticatedBr = localStorage.getItem("user") || '';
        let isAuthenticated:any;

        if(isAuthenticatedBr !== ''){
            const AdminRest = JSON.parse(localStorage.getItem("user") || '');
            isAuthenticated = AdminRest;
        }

        this.adminService.getTemplates(isAuthenticated.id).then(
            (response) => {
                this.setState({rows:response});
            });
    }

    public cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL + "/newtemplate");
      };
    
    public render() { 
            

        return (
            <div className="home">
               <section id="neighborhood_heading" className="cy-custom">
               <MenuPrincipal />  
               
              
               <div style={{height: 500, paddingBottom: '30px', paddingTop:'20px',paddingLeft: '50px', 
                    paddingRight: '50px' }}>
                 <h4>My Templates for Email</h4>
               <Button onClick={this.cancelReg} variant="contained"  style={{margin: '8px',backgroundColor: '#dfbf6d'}}>
                                    New Template
                                </Button>
                            <DataGrid
                            rows={this.state.rows}
                            columns={columns}
                            />
                    


                </div>
                    
                </section>
                <div className="footer-home">
                Mainstreet Realtors Agent Hub
                </div>
            </div>
        );
    }
}

export default Templates;