import * as React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './resources.css';
import FolderTree  from 'react-folder-tree';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';
import { Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import TemplateIcon from '@mui/icons-material/Description'; // Reemplaza con el icono correcto
import DocumentsIcon from '@mui/icons-material/Description'; // Reemplaza con el icono correcto

interface IStateResources {
    numPages: number;
    pageNumber: number;
    fileUrl : string;
    fileName: string;
    width: any;
}


class Resources extends React.Component <{}, IStateResources> {
    
    constructor(props: any) {
        super(props);
    }


    public  goTemplates = () => {
        window.location.assign(process.env.PUBLIC_URL+"/templates");
    };

    public render() { 
            

        return (
            <div className="home">
               <section id="neighborhood_heading" className="cy-custom">
               <MenuPrincipal />  
               <h2>Select a Resource</h2>
               <div style={{ paddingBottom: '30px', paddingTop:'20px' }}>

               <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <IconButton color="primary" >
                        <TemplateIcon fontSize="large" />
                        </IconButton>
                        <Button variant="text" color="primary" onClick={this.goTemplates}>
                        Templates for Email
                        </Button>
                    </Grid>
                </Grid>
                    


                </div>
                    
                </section>
                <div className="footer-home">
                Mainstreet Realtors Agent Hub
                </div>
            </div>
        );
    }
}

export default Resources;