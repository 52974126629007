import * as React from 'react';
import './home.css';

import image_1 from './../../assets/1.Brokermint.jpg'
import image_2 from './../../assets/2-BoomTown.jpg'
import image_3 from './../../assets/3.Blueprint.jpg'
import image_4 from './../../assets/4.Listings.jpg'
import image_5 from './../../assets/5.AgentRoaster.jpg'
import image_6 from './../../assets/6.Vulcan7.jpg'
import image_7 from './../../assets/7.Cole.jpg'
import image_8 from './../../assets/blank.jpg'
import image_9 from './../../assets/eSignature.jpg'
import image_11 from './../../assets/Logos.jpg'
import image_12 from './../../assets/Resources.jpg'
import image_13 from './../../assets/SocialMedia.jpg'
import image_14 from './../../assets/buying.jpg'
import image_15 from './../../assets/listings.jpg'
import Popup from '../Popup/Popup';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';


interface IStateLoginForm {
    buttonAttr: any;
    error: string;
    isLogin: boolean;
}

const isAuthenticatedBr = localStorage.getItem("user") || '';
let isAuthenticated:any;
if(isAuthenticatedBr !== ''){
    const AdminRest = JSON.parse(localStorage.getItem("user") || '');
    isAuthenticated = AdminRest;
}


class Home extends React.Component <{}, IStateLoginForm> {

    public render() {
        return (
            <div className="home">
               <section id="neighborhood_heading" className="cy-custom">
               <MenuPrincipal/>  
                <div id="neighborhoods">
                    
                    <a className="fade-up" target="_blank" href="https://brokermint.com/">
                    <span className="inner ">
                        <span className="title">BROKERMINT</span><br />
                        <span className="subtitle">(Transaction Management System)</span>                        
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_1} data-photosource={image_1} />
                    </a>
                    <a className="fade-up" target="_blank" href="https://leads.boomtownroi.com/">
                    <span className="inner">
                        <span className="title">BOOMTOWN</span><br />
                        <span className="subtitle">(Client Management System)</span>                                
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_2} data-photosource={image_2} /> 
                    </a>
                    <a className="fade-up" target="_blank" href="https://www.mainstreetblueprint.com/">
                    <span className="inner">
                        <span className="title">BLUEPRINT</span><br />
                        <span className="subtitle">(Training)</span>                                

                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_3} data-photosource={image_3} /> 
                    </a>
                    <a className="fade-up" target="_blank" href="https://mainstreetlistings.com">
                    <span className="inner">
                        <span className="title">LISTINGS</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_4} data-photosource={image_4} />
                    </a>
                    <a className="fade-up"  target="_blank" href={process.env.PUBLIC_URL+"/brokers"}>
                    <span className="inner">
                        <span className="title">AGENT ROSTER</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_5} data-photosource={image_5} /> 
                    </a>
                    <a className="fade-up" target="_blank" href="https://www.vulcan7.com/">
                    <span className="inner">
                        <span className="title">VULCAN7 </span><br/>
                        <span className="subtitle">(Dialer System)</span>                                

                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_6} data-photosource={image_6} />
                    </a>
                    
                    <a className="fade-up" target="_blank" href="https://realtyresource.coleinformation.com/">
                    <span className="inner">
                        <span className="title">COLE NEIGHBORHOOD</span><br/>
                        <span className="subtitle">(Data)</span>   
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_7} data-photosource={image_7} />
                    </a>

                    <a className="fade-up" target="_blank" href={"https://drive.google.com/drive/folders/1TNWdON4niShxTn0i3n3LWnfyJoUvwHf6?usp=sharing"}>
                    <span className="inner">
                        <span className="title">SOCIAL MEDIA</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_13} data-photosource={image_13} />
                    </a>

                    <a className="fade-up" target="_blank" href="https://mainstreetlistings.co/Signatures/">
                    <span className="inner">
                        <span className="title">E-SIGNATURES</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_9} data-photosource={image_9} />
                    </a>
                    <a className="fade-up" target="_blank" href="https://drive.google.com/drive/folders/1nOCKKG3iTgVxzezZ9RmTSjQnyxZackt5?usp=sharing">
                    <span className="inner">
                        <span className="title">LOGOS</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_11} data-photosource={image_11} />
                    </a>

                    <a className="fade-up"  target="_blank" href="https://online.fliphtml5.com/gmmue/llqs/">
                     <span className="inner">
                        <span className="title">BUYER PRESENTATION</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_15} data-photosource={image_15} />
                    </a>

                    <a className="fade-up" target="_blank" href="https://online.fliphtml5.com/gmmue/lvyw/#p=1">
                     <span className="inner">
                        <span className="title">SELLER PRESENTATION</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_14} data-photosource={image_14} />
                    </a>

                    
                    <a className="fade-up"  href="/resources">
                     <span className="inner">
                        <span className="title">RESOURCES</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_12} data-photosource={image_12} />
                    </a>
                    <a className="fade-up"  href="#">
                     <span className="inner">
                        <span className="title">VENDORS</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_8} data-photosource={image_8} />
                    </a>
                
                    {isAuthenticated.isAdmin === "1" && (<a className="fade-up" href={process.env.PUBLIC_URL+"/adminpanel"}>
                    <span className="inner">
                        <span className="title">Admin Panel</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_8} data-photosource={image_8} />
                    </a>)}

                </div>
                </section>
                <div className="footer-home">
                Mainstreet Realtors Agent Hub
                </div>
            </div>
        );
    }
}

export default Home;