import * as React from 'react';
import './adminpanel.css';
import darkLogo from './../../assets/hubLogo.png'
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';
import fontBack from './../../assets/totalEmail.png'
import { useParams } from 'react-router-dom';

interface IStateAdminPanelForm {
    id: string;
    code: string;
}

class ViewCode extends React.Component<{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    private codeRef: React.RefObject<HTMLPreElement>; // Declarar la propiedad codeRef

    constructor(props: any) {
        super(props);
        this.codeRef = React.createRef();
        this.state = {
            id: localStorage.getItem("template") || '',
            code: ''
        };
    }

    componentDidMount() {

        let templates = this.state.id;
        this.adminService.getCode(templates).then(
            (response) => {
                this.setState({ code: response });
            });
    }
    public copyToClipboard = () => {
        const codeElement = this.codeRef.current;
    
        if (codeElement) {
          // Seleccionar el contenido del elemento <pre>
          const range = document.createRange();
          range.selectNode(codeElement);
          window.getSelection()?.removeAllRanges();
          window.getSelection()?.addRange(range);
    
          try {
            // Copiar el texto al portapapeles
            document.execCommand('copy');
            // Deseleccionar el texto
            window.getSelection()?.removeAllRanges();
            alert('Html code is copy to clipboard');
          } catch (err) {
            console.error('No se pudo copiar al portapapeles: ', err);
          }
        }
      };
    
      public cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL + "/templates");
    };

    public render() {

       

        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                    <MenuPrincipal />
                    <div style={{
                        height: 500, width: '100%', paddingLeft: '50px',
                        paddingRight: '50px', paddingBottom: '50px', paddingTop: '20px'
                    }}>
                        <h4>View Code for Email</h4>
                        <Button onClick={this.cancelReg} variant="contained"  style={{margin: '8px',backgroundColor: '#808080'}}>
                                    Back to Templates
                                </Button>
                        <br />
                        <br />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                               
                                <div style={{ flex: 1, paddingRight: '20px',textAlign: 'justify', overflowX: 'auto', whiteSpace: 'pre-wrap', fontSize: '6px'}}>
                                <Button onClick={this.copyToClipboard} variant="contained"  style={{margin: '8px',backgroundColor: '#dfbf6d'}}>
                                    Copy to Clipboard
                                </Button><br></br>
                                   <span ref={this.codeRef}> {this.state.code}</span>
                                </div>

                                <div style={{ flex: 2 }}>
                                <iframe
                                srcDoc={this.state.code}
                                title="Contenido HTML"
                                width="700"
                                height="1000"
                                ></iframe>
                                    
                                </div>
                            </div>
                    </div>
                </section >               
                
            <div className="footer-home">
                Mainstreet Realtors Agent Hub
            </div>
            </div >
        );
    }
}

export default ViewCode;