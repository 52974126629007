import * as React from 'react';
import './adminpanel.css';
import darkLogo from './../../assets/hubLogo.png'
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    password: string;
    id: string;
    name:string;
}
  
class ChangePwd extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    constructor(props: any) {
        super(props);
        
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancelReg = this.cancelReg.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);

        this.state = {
            password: '',
            name: '',
            id:  localStorage.getItem("id") || "",
        };
    }
    public handleNameChange = (event:any) => this.setState({name:event.target.value});
    public handlePasswordChange = (event:any) => this.setState({password:event.target.value});
    public handleSubmit = (event:any) => {
        event.preventDefault();

            const data = {
                pwd: this.state.password,
                id: this.state.id,
            };

            this.adminService.changepwd(data).then(
                (response: any) => {
                    window.location.assign(process.env.PUBLIC_URL+"/adminpanel");
                });

    }

    public  cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL+"/adminpanel");
    };

    componentDidMount(){
        this.adminService.getUser({id : this.state.id}).then(
            (response) => {
                this.setState({ name:response.name,
                                });
            });
    }

    public render() {
        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '50px', paddingTop:'20px' }}>
                        <h4>Change Password From User</h4>
                        <br/>
                        <div className="menu-container">
                          <a className="menu-item" href="/adminpanel">ADMIN PANEL</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/createuser">NEW USER</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/announces">ANNOUNCES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/alleSignature">ALL SIGNATURES</a>
                      </div>
                      <br/><br/>
                        <form onSubmit={this.handleSubmit}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                value={this.state.name}
                                fullWidth
                                margin="normal"
                                aria-readonly={true}
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>  
                            <TextField
                                label="Set New Password"
                                variant="outlined"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                fullWidth
                                margin="normal"
                                type="password"
                            />
                            </Grid>
                            <Grid item xs={12} container style={{justifyContent: 'flex-end'}}>
                            <Button type="submit" variant="contained" style={{margin: '8px',backgroundColor: '#dfbf6d'}}>
                                Modify
                            </Button>
                            <Button type="button" onClick={this.cancelReg} variant="contained"  style={{margin: '8px', backgroundColor: '#808080'}}>
                                Cancel
                            </Button>
                            </Grid>
                            </Grid>
                            </form>
                    </div>
                </section> 
                <div className="footer-home">
                Mainstreet Realtors Agent Hub
                </div>
            </div>
        );
    }
}

export default ChangePwd;